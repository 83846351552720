<template>
  <div class="businessDomainForm-container">
    <form-panel
      ref="form"
      :form="form"
      :submitUrl="submitUrl"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccessFunc"
    >
      <template slot="headerSlot">
        <v-button text="返回" @click="goBack"></v-button>
      </template>
      <col2-detail>
        <col2-block :title="title">
          <el-form-item label="业务域名称" prop="businessRegionName" :rules="businessRegionNameRules">
            <v-input v-model="form.businessRegionName" :maxlength="8"/>
          </el-form-item>
          <el-form-item v-show="!defaultRegionIds" label="授权对象" :rules="[{ required: true, validator: regionIdsValid, trigger: 'blur1'}]" prop="regionIds">
            <chosen-list-panel ref="RegionRef" :list.sync="regionIds" @select="toSelectRegionIds" :beforeRemove="beforeRemove"></chosen-list-panel>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="管理后台">
          <el-form-item label="管理后台logo"  prop="businessLogo" :rules="[{ type:'array', required: true, message: '请选择管理后台logo', trigger: 'change' }]">
            <v-uploader class="uploader" :action="uploadCoverURL" :fileWidth="225" :fileHeight="44" :fileList.sync="form.businessLogo" operate="none" />
            <span style="margin-left: 20px;">注：建议宽225px高44px，图片最大不超过2M</span>
          </el-form-item>
          <el-form-item label="管理后台皮肤"  prop="businessSkin" :rules="[{ required: true, message: '请选择管理后台皮肤', trigger: 'change' }]">
            <checkbox-plus type="radio" :checkClass="['checkbox-plus-skin']" :options="businessSkinOps" :value.sync="form.businessSkin" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="H5模板">
         <el-form-item label="访客通行证" prop="visitorId" :rules="[{ required: true, message: '请选择访客通行证', trigger: 'change'}]">
            <v-select2 v-model="form.visitorId" v-bind="visitorParams" placeholder="查询模板" :backText="visitorName" @onChange="changeVisitor" :width="width"></v-select2>
          </el-form-item>
          <el-form-item label="H5应用授权" prop="h5AppId" :rules="[{ required: true, message: '请选择H5应用授权', trigger: 'change'}]">
            <v-select2 v-model="form.h5AppId" v-bind="h5Application" placeholder="查询模板" :backText="h5AppName" @onChange="changeH5App" :width="width"></v-select2>
          </el-form-item>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="APP模板">
          <span class="app-navigation-name-list">
            <el-form-item
              v-for="(item, index) in form.appNavigationNames"
              :key="index"
              :label="index === 0 ? 'APP底部导航' : ''"
              :rules="[{ required: true, message: '请输入APP底部导航', trigger: 'change'}]"
              :prop="'appNavigationNames.' + index + '.text'"
              :label-width="index === 0 ? '' : '20px'"
            >
              <v-input v-model="item.text" :maxlength="2"/>
            </el-form-item>
          </span>
        </col2-block>
      </col2-detail>
      <col2-detail>
        <col2-block title="其他业务">
          <el-form-item label="商品分享" v-show="isShareVisible">
           <checkbox-plus style="margin-top: 5px;" type="default" :options="isShareOps" :value.sync="checkIsShare" mode="checkbox-plus"></checkbox-plus>
          </el-form-item>
          <el-form-item label="商品外赠" v-show="isPresentVisible">
            <checkbox-plus style="margin-top: 5px;" type="default" :options="isPresentOps" :value.sync="checkIsPresent" mode="checkbox-plus"></checkbox-plus>
          </el-form-item>
          <el-form-item label="分享平台">
            <v-select2 style="display:inline-block;" v-model="form.wxAccountId" v-bind="wxAccountParams" placeholder="查询微信平台账号" :backText="wxAccountName" @onChange="changeWxAccount" :width="width" />
            <v-select2 style="display:inline-block;margin-left:20px;" v-model="form.qqAccountId" v-bind="qqAccountParams" placeholder="查询QQ平台账号" :backText="qqAccountName" @onChange="changeQqAccount" :width="width" />
          </el-form-item>
          <el-form-item label="增值业务">
            <checkbox-plus style="margin-top: 5px;" type="default" :options="userAccountOps" :value.sync="userAccount" mode="checkbox-plus" />
          </el-form-item>
          <el-form-item label="引导开户" v-if="userAccount.includes('2')">
            <v-select v-model="accountOpenType" :options="accountOpenTypeOps"></v-select>
          </el-form-item>
          <el-form-item label="预存划扣">
            <v-select v-model="preDepositDeductionType" :options="preDepositDeductionOps"></v-select>
          </el-form-item>
          <el-form-item label="授权渠道">
            <checkbox-plus style="margin-top: 5px;" type="default" :options="authChannelOps" :value.sync="authChannel" mode="checkbox-plus" />
          </el-form-item>
        </col2-block>
      </col2-detail>
      <template #saveBeforeSlot>
        <v-button v-if="showReturn" text="返回"  @click="returnClick" />
      </template>
    </form-panel>
    <multi-select
      title="选择公司"
      :isShow.sync="isShow"
      :searchUrl="getRegionUrl"
      :headers="businessRegionTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :selectable="selectable"
      :beforeSave="beforeSave"
      :backFill.sync="regionIds">
        <template #searchSlot>
          <v-input v-model="searchParams.regionName" label="公司名称"></v-input>
          <v-input v-model="searchParams.communityName" label="关联项目"></v-input>
          <v-input v-model="searchParams.businessRegionName" label="关联业务域"></v-input>
          <v-datepicker label="创建时间" :appendToBody="false" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
        </template>
    </multi-select>
  </div>
</template>
<script>
import {
  templateUrl,
  createUrl,
  updateUrl,
  getDetailUrl,
  getRegionUrl,
  uploadURL
} from './api'
import { Col2Block, Col2Detail, MultiSelect, ChosenListPanel } from '@/components/bussiness'
import { vUploader } from 'components/control'
import { CheckboxPlus } from 'components/bussiness/index'

export default {
  name: 'BusinessDomainForm',
  components: {
    Col2Block,
    Col2Detail,
    MultiSelect,
    ChosenListPanel,
    vUploader,
    CheckboxPlus
  },
  props: {
    setBreadcrumb: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: '基础信息'
    },
    submitText: String,
    showReturn: {
      type: Boolean,
      default: false
    },
    defaultRegionIds: Array,
    submitSuccess: Function
  },
  data () {
    const validateBusinessRegionName = (rule, value, callback) => {
      let regExp = /^[\w\u4e00-\u9fa5]+$/
      if (!regExp.test(value)) {
        callback(new Error('请正确输入业务域名称'))
      } else {
        callback()
      }
    }
    return {
      width: 200,
      isShareVisible: false,
      isPresentVisible: false,
      submitUrl: this.$route.query.id ? updateUrl : createUrl,
      getRegionUrl: getRegionUrl,
      submitConfig: {
        submitMethod: this.$route.query.id ? 'PUT' : 'POST',
        // submitContentType: 'application/json;charset=UTF-8',
        submitText: this.submitText
      },
      businessRegionNameRules: [
        { required: true, message: '业务域名称', trigger: 'blur' },
        { validator: validateBusinessRegionName, trigger: 'blur' }
      ],
      uploadCoverURL: `${uploadURL}head`,
      isShareOps: [
        {
          value: '0'
        }
      ],
      isPresentOps: [
        {
          value: '0'
        }
      ],
      userAccountOps: [
        {
          label: '开放用户积分',
          value: '1'
        },
        {
          label: '开放钱包充值',
          value: '2'
        },
        {
          label: '开启用户会员',
          value: '3'
        }
      ],
      authChannelOps: [],
      checkIsShare: ['0'], // 默认开启
      checkIsPresent: [], // 默认关闭
      userAccount: [],
      authChannel: [],
      businessSkinOps: [],
      form: {
        businessRegionName: '',
        userAppCode: '',
        serviceAppCode: '',
        businessLogo: [],
        visitorId: '',
        h5AppId: '',
        appNavigationNames: [
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: ''
          },
          {
            text: ''
          }
        ],
        wxAccountId: '',
        qqAccountId: '',
        businessSkin: ''
      },
      visitorParams: {
        searchUrl: `${templateUrl}` + `?businessType=2&mouldType=visitor_mould`,
        request: {
          text: 'mouldName',
          value: 'mouldCode'
        },
        response: {
          text: 'mouldName',
          value: 'mouldCode'
        }
      },
      h5Application: {
        searchUrl: `${templateUrl}` + `?businessType=2&mouldType=h5_auth_mould`,
        request: {
          text: 'mouldName',
          value: 'mouldCode'
        },
        response: {
          text: 'mouldName',
          value: 'mouldCode'
        }
      },
      wxAccountParams: {
        searchUrl: `${templateUrl}` + `?businessType=4&mouldType=sharing_plat_account_weixin`,
        request: {
          text: 'mouldName',
          value: 'mouldCode'
        },
        response: {
          text: 'mouldName',
          value: 'mouldCode'
        }
      },
      qqAccountParams: {
        searchUrl: `${templateUrl}` + `?businessType=4&mouldType=sharing_plat_account_qq`,
        request: {
          text: 'mouldName',
          value: 'mouldCode'
        },
        response: {
          text: 'mouldName',
          value: 'mouldCode'
        }
      },
      bluetoothExtraParams: {
        communityId: ''
      },
      regionIds: [],
      editRegionIds: [],
      isShow: false,
      swalRegionId: false,
      searchParams: {
        regionName: '',
        communityName: '',
        businessRegionName: '',
        startTime: '',
        endTime: ''
      },
      visitorName: '',
      h5AppName: '',
      wxAccountName: '',
      qqAccountName: '',
      businessRegionTableHeader: [
        {
          prop: 'regionName',
          label: '公司名称'
        }, {
          prop: 'communityNames',
          label: '关联项目'
        }, {
          prop: 'businessRegionName',
          label: '关联业务域'
        }, {
          prop: 'intime',
          label: '创建时间'
        }
      ],
      responseParams: {
        id: 'id',
        name: 'regionName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      detailItem: undefined,
      accountOpenTypeOps: [
        {
          text: '二三类户',
          value: 1
        },
        {
          text: '仅二类户',
          value: 2
        },
        {
          text: '仅三类户',
          value: 3
        },
      ],
      accountOpenType: 3, // 引导开户, 默认为 仅三类户
      preDepositDeductionOps: [
        {
            text: '钱包预存',
            value: '1'
          },
          {
            text: '物业预缴',
            value: '2'
          },
          {
            text: '无',
            value: '9'
          },
      ],
      preDepositDeductionType: '1', // 预存划扣, 默认为 钱包预存
    }
  },
  async created () {
    let { id } = this.$route.query
    if (id) {
      this.getDetailData()
      if (this.setBreadcrumb) {
        this.$setBreadcrumb('编辑')
      }
    } else {
      if (this.setBreadcrumb) {
        this.$setBreadcrumb('新增')
      }
      const defaultAppNavigationNames = ['首页', '服务', '发现', '我的']
      this.setAppNavigationNames(defaultAppNavigationNames)
    }
    this.getOps({
      businessType: 6,
      mouldType: 'auth_channel_list'
    }, (ops) => {
      this.authChannelOps = ops
    })
    this.getOps({
      businessType: 7,
      mouldType: 'manage_skin_mould'
    }, (ops) => {
      let { id } = this.$route.query
      if (id === undefined) {
        this.form.businessSkin = ops[0].value
      }
      this.businessSkinOps = ops
    })
  },
  watch: {
    defaultRegionIds (newValue) {
      this.regionIds = newValue
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
      this.$router.go(-1)
    },
    async getOps (params, cb) {
      const { status, data } = await this.$axios.get(`${templateUrl}?businessType=${params.businessType}&mouldType=${params.mouldType}`)
      if (status === 100) {
        if (data && data.length) {
          const ops = []
          data.forEach(item => {
            ops.push({
              label: item.mouldName,
              value: item.mouldCode
            })
          })
          cb && cb(ops)
        }
      }
    },
    setAppNavigationNames (list) {
      this.form.appNavigationNames.forEach((item, index) => {
        item.text = list[index]
      })
    },
    selectable (data) {
      return data.hasBusinessRegion === 1
    },
    getDetailData () {
      const { id } = this.$route.query
      const _this = this
      this.$axios.get(`${getDetailUrl}` + `/` + id)
        .then(res => {
          let returnStatus = String(res.status)
          if (returnStatus === '100') {
            const data = res.data
            if (data) {
              // 处理 融基座 兄弟连OS-1977 【融1.15】【业主端】用户打开业主端，显示白屏OS-1978 问题
              _this.detailItem = data
            }
            this.form.id = data.id
            this.form.businessRegionName = data.businessRegionName
            this.regionIds = data.regions.map(item => {
              return {
                text: item.regionName,
                id: item.regionId
              }
            })
            this.editRegionIds = data.regions.map(item => {
              return {
                text: item.regionName,
                id: item.regionId
              }
            })
            this.form.businessLogo = data.businessLogo ? [{ url: data.businessLogo }] : []
            data.businessDetails.forEach(item => {
              if (item.mouldType === 'visitor_mould') {
                _this.form.visitorId = item.mouldCode
                _this.visitorName = item.mouldName
              }
              if (item.mouldType === 'h5_auth_mould') {
                _this.form.h5AppId = item.mouldCode
                _this.h5AppName = item.mouldName
              }
              if (item.mouldType === 'product_sharing' && item.mouldCode === '0') {
                _this.checkIsShare = item.mouldCode
              }
              if (item.mouldType === 'product_handsel' && item.mouldCode === '0') {
                _this.checkIsPresent = item.mouldCode
              }
              if (item.mouldType === 'manage_skin_mould') {
                _this.form.businessSkin = item.mouldCode
              }
              if (item.mouldType === 'sharing_plat_account_weixin') {
                _this.form.wxAccountId = item.mouldCode
                _this.wxAccountName = item.mouldName
              }
              if (item.mouldType === 'sharing_plat_account_qq') {
                _this.form.qqAccountId = item.mouldCode
                _this.qqAccountName = item.mouldName
              }
              if (item.mouldType === 'open_user_integral' && item.mouldCode === '1') {
                _this.userAccount.push('1')
              }
              if (item.mouldType === 'open_wallet_recharge' && item.mouldCode === '1') {
                _this.userAccount.push('2')
                this.accountOpenType = item.accountOpenType - 0 || 3
              }
              if (item.mouldType === 'open_user_member' && item.mouldCode === '1') {
                _this.userAccount.push('3')
              }
              if (item.mouldType === 'auth_channel_list') {
                _this.authChannel.push(item.mouldCode)
              }
              if (item.mouldType === 'app_navigation_names') {
                const appNavigationNames = JSON.parse(item.mouldCode)
                this.setAppNavigationNames(appNavigationNames)
              }
              if (item.mouldType === 'open_advance_type') {
                this.preDepositDeductionType = item.mouldCode
              }
            })
          }
        })
    },
    async beforeSave (list) {
      let { id } = this.$route.query
      if (id) {
        this.changeMultiData(list)
        if (this.swalRegionId) {
          let isOk = await this.$confirm('确认修改授权对象？', {
            title: '提示'
          })
          this.isShow = isOk !== true
          return isOk
        } else {
          this.isShow = false
          return true
        }
      } else {
        this.isShow = false
        return true
      }
    },
    changeMultiData (list) {
      this.editRegionIds.forEach(item => {
        this.swalRegionId = true
        let swalMsg = false
        list.forEach(data => {
          if (data.id === item.id) {
            swalMsg = true
          }
        })
        this.swalRegionId = swalMsg !== true
      })
    },
    async beforeRemove (list) {
      let { id } = this.$route.query
      if (id) {
        this.changeMultiData(list)
        if (this.swalRegionId) {
          let isOk = await this.$confirm('确认修改授权对象？', {
            title: '提示'
          })
          return isOk
        } else {
          return true
        }
      } else {
        return true
      }
    },
    toSelectRegionIds () {
      this.isShow = true
    },
    regionIdsValid (rule, value, callback) {
      if (this.regionIds.length > 0) {
        callback()
      } else {
        callback(new Error('请选择授权对象！'))
      }
    },
    changeVisitor (data) {
      this.visitorName = data ? data.mouldName : ''
    },
    changeH5App (data) {
      this.h5AppName = data ? data.mouldName : ''
    },
    changeWxAccount (data) {
      this.wxAccountName = data ? data.mouldName : ''
    },
    changeQqAccount (data) {
      this.qqAccountName = data ? data.mouldName : ''
    },
    submitBefore (data) {
      data.businessLogo = this.form.businessLogo[0].url
      data.regionIds = this.regionIds.map(item => item.id)
      const isShare = this.checkIsShare[0] === '0' ? '0' : '1'
      const isPresent = this.checkIsPresent[0] === '0' ? '0' : '1'
      // 开放用户积分
      const isOpenUserIntegral = this.userAccount.indexOf('1') === -1 ? '0' : '1'
      // 开放钱包充值
      const isOpenWalletRecharge = this.userAccount.indexOf('2') === -1 ? '0' : '1'
      // 开放用户会员
      const isOpenUserMember = this.userAccount.indexOf('3') === -1 ? '0' : '1'
      // APP底部导航
      let appNavigationNames = []
      this.form.appNavigationNames.forEach(item => {
        appNavigationNames.push(item.text)
      })
      appNavigationNames = JSON.stringify(appNavigationNames)
      const businessDetails = [
        { businessKey: 'visitor_mould', businessType: 2, businessValue: data.visitorId, mouldName: this.visitorName },
        { businessKey: 'h5_auth_mould', businessType: 2, businessValue: data.h5AppId, mouldName: this.h5AppName },
        { businessKey: 'product_sharing', businessType: 3, businessValue: isShare, mouldName: '商品分享' },
        { businessKey: 'product_handsel', businessType: 3, businessValue: isPresent, mouldName: '商品外赠' },
        { businessKey: 'open_user_integral', businessType: 5, businessValue: isOpenUserIntegral, mouldName: '开放用户积分' },
        { businessKey: 'open_wallet_recharge', businessType: 5, businessValue: isOpenWalletRecharge, accountOpenType: this.accountOpenType, mouldName: '开放钱包充值' },
        { businessKey: 'open_user_member', businessType: 5, businessValue: isOpenUserMember, mouldName: '开放用户会员' },
        { businessKey: 'manage_skin_mould', businessType: 7, businessValue: this.form.businessSkin, mouldName: '管理后台皮肤' },
        { businessKey: 'app_navigation_names', businessType: 8, businessValue: appNavigationNames, mouldName: 'APP底部导航' },
        { businessKey: 'open_advance_type', businessType: 5, businessValue: this.preDepositDeductionType, accountOpenType: 2, mouldName: '预存划扣' },
      ]
      // 分享平台 - 微信
      if (data.wxAccountId) {
        businessDetails.push({ businessKey: 'sharing_plat_account_weixin', businessType: 4, businessValue: data.wxAccountId, mouldName: this.wxAccountName })
      }
      // 分享平台 - QQ
      if (data.qqAccountId) {
        businessDetails.push({ businessKey: 'sharing_plat_account_qq', businessType: 4, businessValue: data.qqAccountId, mouldName: this.qqAccountName })
      }
      // 授权渠道
      const authChannelOps = this.authChannelOps
      if (authChannelOps && authChannelOps.length) {
        authChannelOps.forEach((item) => {
          if (this.authChannel.indexOf(item.value) !== -1) {
            businessDetails.push({ businessKey: 'auth_channel_list', businessType: 6, businessValue: item.value, mouldName: item.label })
          }
        })
      }

      // 处理 融基座 兄弟连OS-1977 【融1.15】【业主端】用户打开业主端，显示白屏OS-1978 问题
      if (this.detailItem && this.detailItem.businessDetails) {
        let tempList = this.detailItem.businessDetails
        tempList.forEach(item => {
          if (item.businessType === 9 || item.businessType === 10) {
            businessDetails.push({ businessKey: item.mouldType, businessType: item.businessType, businessValue: item.mouldCode, mouldName: item.mouldName })
          }
        })
      }
      data.businessDetails = businessDetails
      delete data.visitorId
      delete data.h5AppId
      delete data.wxAccountId
      delete data.qqAccountId
      delete data.businessSkin
      delete data.appNavigationNames

      if (this.$refs.RegionRef && this.$refs.RegionRef.data) {
        let list = this.$refs.RegionRef.data
        let regionStr = list.map(item => item.text).join(',')
        let dataObject = `${data.businessRegionName}-${regionStr}`
        data.dataObject = dataObject
      }

      return true
    },
    submitSuccessFunc () {
      if (this.submitSuccess) {
        this.submitSuccess()
        return false
      }
      return true
    },
    returnClick () {
      this.$emit('returnClick')
    }
  }
}
</script>

<style lang="scss">
.businessDomainForm-container {
  .uploader {
    .noImg {
      flex-direction: row;
      .addIcon{
        box-sizing: border-box;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 6px 6px 12px;
        font-size: 45px;
      }
      .addText{
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
  }
  height: 100%;
  .checkbox-plus-skin {
    flex-wrap: nowrap;
    .check-item {
      min-width: 140px;
    }
  }
  .app-navigation-name-list {
    display: flex;
  }
}
</style>
