var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "businessDomainForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitBefore: _vm.submitBefore,
              submitSuccess: _vm.submitSuccessFunc,
            },
            scopedSlots: _vm._u([
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _vm.showReturn
                      ? _c("v-button", {
                          attrs: { text: "返回" },
                          on: { click: _vm.returnClick },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "template",
            { slot: "headerSlot" },
            [
              _c("v-button", {
                attrs: { text: "返回" },
                on: { click: _vm.goBack },
              }),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: _vm.title } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务域名称",
                        prop: "businessRegionName",
                        rules: _vm.businessRegionNameRules,
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { maxlength: 8 },
                        model: {
                          value: _vm.form.businessRegionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "businessRegionName", $$v)
                          },
                          expression: "form.businessRegionName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.defaultRegionIds,
                          expression: "!defaultRegionIds",
                        },
                      ],
                      attrs: {
                        label: "授权对象",
                        rules: [
                          {
                            required: true,
                            validator: _vm.regionIdsValid,
                            trigger: "blur1",
                          },
                        ],
                        prop: "regionIds",
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        ref: "RegionRef",
                        attrs: {
                          list: _vm.regionIds,
                          beforeRemove: _vm.beforeRemove,
                        },
                        on: {
                          "update:list": function ($event) {
                            _vm.regionIds = $event
                          },
                          select: _vm.toSelectRegionIds,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "管理后台" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "管理后台logo",
                        prop: "businessLogo",
                        rules: [
                          {
                            type: "array",
                            required: true,
                            message: "请选择管理后台logo",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-uploader", {
                        staticClass: "uploader",
                        attrs: {
                          action: _vm.uploadCoverURL,
                          fileWidth: 225,
                          fileHeight: 44,
                          fileList: _vm.form.businessLogo,
                          operate: "none",
                        },
                        on: {
                          "update:fileList": function ($event) {
                            return _vm.$set(_vm.form, "businessLogo", $event)
                          },
                          "update:file-list": function ($event) {
                            return _vm.$set(_vm.form, "businessLogo", $event)
                          },
                        },
                      }),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v("注：建议宽225px高44px，图片最大不超过2M"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "管理后台皮肤",
                        prop: "businessSkin",
                        rules: [
                          {
                            required: true,
                            message: "请选择管理后台皮肤",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "radio",
                          checkClass: ["checkbox-plus-skin"],
                          options: _vm.businessSkinOps,
                          value: _vm.form.businessSkin,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "businessSkin", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "H5模板" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "访客通行证",
                        prop: "visitorId",
                        rules: [
                          {
                            required: true,
                            message: "请选择访客通行证",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询模板",
                              backText: _vm.visitorName,
                              width: _vm.width,
                            },
                            on: { onChange: _vm.changeVisitor },
                            model: {
                              value: _vm.form.visitorId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "visitorId", $$v)
                              },
                              expression: "form.visitorId",
                            },
                          },
                          "v-select2",
                          _vm.visitorParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "H5应用授权",
                        prop: "h5AppId",
                        rules: [
                          {
                            required: true,
                            message: "请选择H5应用授权",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "查询模板",
                              backText: _vm.h5AppName,
                              width: _vm.width,
                            },
                            on: { onChange: _vm.changeH5App },
                            model: {
                              value: _vm.form.h5AppId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "h5AppId", $$v)
                              },
                              expression: "form.h5AppId",
                            },
                          },
                          "v-select2",
                          _vm.h5Application,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c("col2-block", { attrs: { title: "APP模板" } }, [
                _c(
                  "span",
                  { staticClass: "app-navigation-name-list" },
                  _vm._l(_vm.form.appNavigationNames, function (item, index) {
                    return _c(
                      "el-form-item",
                      {
                        key: index,
                        attrs: {
                          label: index === 0 ? "APP底部导航" : "",
                          rules: [
                            {
                              required: true,
                              message: "请输入APP底部导航",
                              trigger: "change",
                            },
                          ],
                          prop: "appNavigationNames." + index + ".text",
                          "label-width": index === 0 ? "" : "20px",
                        },
                      },
                      [
                        _c("v-input", {
                          attrs: { maxlength: 2 },
                          model: {
                            value: item.text,
                            callback: function ($$v) {
                              _vm.$set(item, "text", $$v)
                            },
                            expression: "item.text",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "其他业务" } },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShareVisible,
                          expression: "isShareVisible",
                        },
                      ],
                      attrs: { label: "商品分享" },
                    },
                    [
                      _c("checkbox-plus", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          type: "default",
                          options: _vm.isShareOps,
                          value: _vm.checkIsShare,
                          mode: "checkbox-plus",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.checkIsShare = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPresentVisible,
                          expression: "isPresentVisible",
                        },
                      ],
                      attrs: { label: "商品外赠" },
                    },
                    [
                      _c("checkbox-plus", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          type: "default",
                          options: _vm.isPresentOps,
                          value: _vm.checkIsPresent,
                          mode: "checkbox-plus",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.checkIsPresent = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享平台" } },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticStyle: { display: "inline-block" },
                            attrs: {
                              placeholder: "查询微信平台账号",
                              backText: _vm.wxAccountName,
                              width: _vm.width,
                            },
                            on: { onChange: _vm.changeWxAccount },
                            model: {
                              value: _vm.form.wxAccountId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "wxAccountId", $$v)
                              },
                              expression: "form.wxAccountId",
                            },
                          },
                          "v-select2",
                          _vm.wxAccountParams,
                          false
                        )
                      ),
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            staticStyle: {
                              display: "inline-block",
                              "margin-left": "20px",
                            },
                            attrs: {
                              placeholder: "查询QQ平台账号",
                              backText: _vm.qqAccountName,
                              width: _vm.width,
                            },
                            on: { onChange: _vm.changeQqAccount },
                            model: {
                              value: _vm.form.qqAccountId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "qqAccountId", $$v)
                              },
                              expression: "form.qqAccountId",
                            },
                          },
                          "v-select2",
                          _vm.qqAccountParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "增值业务" } },
                    [
                      _c("checkbox-plus", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          type: "default",
                          options: _vm.userAccountOps,
                          value: _vm.userAccount,
                          mode: "checkbox-plus",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.userAccount = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.userAccount.includes("2")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "引导开户" } },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.accountOpenTypeOps },
                            model: {
                              value: _vm.accountOpenType,
                              callback: function ($$v) {
                                _vm.accountOpenType = $$v
                              },
                              expression: "accountOpenType",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "预存划扣" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.preDepositDeductionOps },
                        model: {
                          value: _vm.preDepositDeductionType,
                          callback: function ($$v) {
                            _vm.preDepositDeductionType = $$v
                          },
                          expression: "preDepositDeductionType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "授权渠道" } },
                    [
                      _c("checkbox-plus", {
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          type: "default",
                          options: _vm.authChannelOps,
                          value: _vm.authChannel,
                          mode: "checkbox-plus",
                        },
                        on: {
                          "update:value": function ($event) {
                            _vm.authChannel = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("multi-select", {
        attrs: {
          title: "选择公司",
          isShow: _vm.isShow,
          searchUrl: _vm.getRegionUrl,
          headers: _vm.businessRegionTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          selectable: _vm.selectable,
          beforeSave: _vm.beforeSave,
          backFill: _vm.regionIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            _vm.regionIds = $event
          },
          "update:back-fill": function ($event) {
            _vm.regionIds = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "公司名称" },
                  model: {
                    value: _vm.searchParams.regionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "regionName", $$v)
                    },
                    expression: "searchParams.regionName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "关联项目" },
                  model: {
                    value: _vm.searchParams.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "communityName", $$v)
                    },
                    expression: "searchParams.communityName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "关联业务域" },
                  model: {
                    value: _vm.searchParams.businessRegionName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "businessRegionName", $$v)
                    },
                    expression: "searchParams.businessRegionName",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间",
                    appendToBody: false,
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }