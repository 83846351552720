// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}businessRegions`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}exportBusinessRegionList`
// 授权列表
const regionIdURL = `${API_CONFIG.butlerBaseURL}region/getRegionBusinessRelationship?fromSelect2=0`
// 新增
const createUrl = `${API_CONFIG.butlerBaseURL}businessRegion`
// 详情
const getDetailUrl = `${API_CONFIG.butlerBaseURL}businessRegion`
// 编辑
const updateUrl = `${API_CONFIG.butlerBaseURL}businessRegion`
// 上传图片
const uploadURL = `${API_CONFIG.uploadURL}?module=`
// 授权对象列表
const getRegionUrl = `${API_CONFIG.butlerBaseURL}region/getRegionBusinessRelationship`
// 模板
const templateUrl = `${API_CONFIG.butlerBaseURL}businessMouldInfo/select2`

export {
  getListURL,
  exportListURL,
  regionIdURL,
  createUrl,
  getDetailUrl,
  updateUrl,
  uploadURL,
  getRegionUrl,
  templateUrl
}
